import React, { Component } from 'react';

class Social extends Component {
    render() {
        return (
            <div class="social">
                <a href="https://github.com/davinci26" target="_blank" rel="noopener noreferrer"><i class="fab fa-github"></i></a>
                <a href="https://twitter.com/davinci260" target="_blank" rel="noopener noreferrer"><i class="fab fa-twitter"></i></a>
                <a href="https://www.linkedin.com/in/nanopouloss/" target="_blank" rel="noopener noreferrer"><i class="fab fa-linkedin-in"></i></a>
                <a href="https://medium.com/@davinci260" target="_blank" rel="noopener noreferrer"><i class="fab fa-medium-m"></i></a>
            </div>
        )
    }
}

export default Social

import React, { Component } from 'react';
import { InlineWidget } from 'react-calendly';



class Home extends Component {
    render() {
        return (
            <div className="condiv">
                {/* <img src={grecePic} className="widePic"></img> */}
                <h1 className="subtopic">Hi, I'm Sotiris</h1>
                <br></br>
                <p> I like solving problems using technology and code. I am a Software engineer at Microsoft in the <a href="https://azure.microsoft.com/en-ca/"> Azure Edge OS team</a>.
                I am trying to make Windows the best Edge computing platform. Currently I am contributing to <a href="https://www.envoyproxy.io/">Envoy Proxy</a>.
                <br></br><br></br>
                Before that I was running my own startup  in Greece and I was writing software for a research lab at
                the National Technical University of Athens (NTUA). On my spare time, I research novel business models enabled by new technologies.
                I like to <a href="https://medium.com/@davinci260/a-play-straight-from-the-book-buying-us-bonds-7e9959e83e98">backtest investment strategies</a> and explore new technologies such as <a href="https://medium.com/@davinci260/discovering-alexa-a-developers-perspective-c794a057a55c"> voice-assistants </a>
                 and <a href="https://github.com/davinci26/Codigo-Network">blockchain</a>. I run a managed investment porfolio and I am looking to expand into angel investing.</p>
                <br></br>
                <p>
                    My DMs are open on <a href="https://twitter.com/davinci260">Twitter</a>. I am also experimenting with open office hours. Let me know how I can help you!
                </p>
                <InlineWidget
                    pageSettings={{
                        backgroundColor: 'ffffff',
                        hideEventTypeDetails: false,
                        hideLandingPageDetails: false,
                        primaryColor: '00a2ff',
                        textColor: '4d5055'
                    }}
                    prefill={{
                        email: 'test@test.com',
                        firstName: 'Jon',
                        lastName: 'Snow',
                        name: 'Jon Snow'
                    }}
                    styles={{
                        height: '700px'
                    }}
                    url="https://calendly.com/sotirisnan/30min"
                />
            </div >
        )
    }
}

export default Home

import React, { Component } from 'react';
import Social from '../components/Social'
import Emoji from '../components/Emoji';
import { StickyContainer } from 'react-sticky';

import profilepic from '../img/pic.jpg'

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'NavItemActive': ''
        }
    }
    activeitem = (x) => {
        if (this.state.NavItemActive.length > 0) {
            document.getElementById(this.state.NavItemActive).classList.remove('active');
        }
        this.setState({ 'NavItemActive': x }, () => {
            document.getElementById(this.state.NavItemActive).classList.add('active');
        });
    };
    render() {
        return (
            <StickyContainer style={{ width: '30%' }}>
                <nav>
                    <img src={profilepic} className="profilepic" alt="Sotiris"></img>
                    <ul>
                        <div>
                            <h3 class="test">Sotiris <Emoji symbol="✌️" label="victory sign" /></h3>
                            <Social></Social>
                            <p>
                                Software Engineer at @Microsoft and this is my internet <Emoji symbol="&#127969;" label="home" />.
                            <br></br><br></br>
                                {/* I like all things tech and business. I love crazy ideas. */}
                            </p>
                        </div>
                    </ul>
                </nav>
            </StickyContainer>
        )
    }
}

export default Navbar
